import { Nullable } from '@tager/web-core';

export const isValidHex = (hex: string): boolean =>
  /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

export const getChunksFromString = (
  st: string,
  chunkSize: number
): Nullable<RegExpMatchArray> => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

export const convertHexUnitTo256 = (hex: string): number =>
  parseInt(hex.repeat(2 / hex.length), 16);

export const getAlphafloat = (a: number, alpha?: number): number => {
  if (!alpha || alpha < 0 || alpha > 1) {
    return 1;
  }

  return a / 255;
};

export const hexToRGBAf = (hex: string, alpha?: number): string => {
  if (!isValidHex(hex)) {
    throw new Error('Invalid HEX');
  }

  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);

  if (!hexArr) {
    return '';
  }

  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);

  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha ?? 1)})`;
};

export const getValueRgbAsArray = (rgb: string): number[] => {
  const firstIndex = rgb.indexOf('(');
  const lastIndex = rgb.indexOf(')');
  const code = rgb.slice(firstIndex, lastIndex);

  return code.split(',').map((num) => Number(num));
};

export const isLightRgbColor = (rgb: string): boolean => {
  const values = getValueRgbAsArray(rgb);

  return values[0] >= 127 || values[1] >= 127 || values[3] >= 127;
};

export const isLightHexColor = (hex: string): boolean =>
  isLightRgbColor(hexToRGBAf(hex));

export const isLightColor = (color: string): boolean =>
  isValidHex(color) ? isLightHexColor(color) : isLightRgbColor(color);

export const isGradientColor = (gradient: string): boolean =>
  /\w+-gradient\(.*?\)\)|\w+-gradient\(.*?\)/.test(gradient);

export const isWhiteColor = (color: string): boolean =>
  /^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i.test(
    color
  );
