import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import SortSelectArrowDownIcon from '@/assets/svg/sort_select_arrow_down.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { SortSelectProps } from './SortSelect.types';

export function SortSelect<T>({
  title = 'Сортировать по',
  value,
  onChange,
  options,
  className,
}: SortSelectProps<T>) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem?.value === value.value) {
        return;
      }

      onChange?.(selectedItem ?? options[0]);
    },
  });

  return (
    <Component className={className}>
      <Display type="button" {...getToggleButtonProps()}>
        <DisplayText>
          {title} <DisplayName>{selectedItem?.label}</DisplayName>
        </DisplayText>
        <DisplayIcon isOpen={isOpen}>
          <SortSelectArrowDownIcon />
        </DisplayIcon>
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                isHighlighted={highlightedIndex === index}
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const Display = styled.button`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black100};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}
`;

const DisplayText = styled.span``;

const DisplayName = styled.span`
  font-weight: 700;
`;

const DisplayIcon = styled.span<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;

  svg {
    fill: ${(props) => props.theme.black100};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 30;

  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};
`;

const OptionList = styled.ul`
  padding: 4px 0;
`;

const OptionItem = styled.li<{ isHighlighted: boolean; isSelected: boolean }>`
  padding: 5px 12px;

  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.black200};
  white-space: nowrap;

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
    `};

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${colors.gray800};
    `};
`;

const NoOptions = styled.div``;
