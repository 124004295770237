import React, { useEffect } from 'react';

import { Nullable } from '@tager/web-core';

export function useOnClickOutside(
  ref: React.MutableRefObject<Nullable<HTMLElement>>,
  handler: (event: MouseEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !ref.current ||
        (event.target instanceof Element && ref.current.contains(event.target))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
}
