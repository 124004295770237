import { SwitchOptionsType } from '@/components/Switch/types';
import { Dealer } from '@/typings/model';

export const switchOptions: SwitchOptionsType = {
  from: { label: 'Новые авто', value: 'Новые авто' },
  to: {
    label: 'Автомобили с пробегом',
    value: 'Автомобили с пробегом',
  },
};

export const getAverageRatingAndCountReviewsDealers = (
  dealers: Dealer[]
): { rating: number; ratingCount: number } => {
  let rating = 0;
  let ratingCount = 0;

  dealers.forEach((dealer) => {
    rating += dealer.rating;
    ratingCount += dealer.reviewsCount;
  });

  return { rating: Number((rating / dealers.length).toFixed(2)), ratingCount };
};
